@import "../../styles/mixins.scss";

.contact {
  .c-header {
    @include fontProperties(20px, 600, #fff);
    @include animate(0.1s);
  }
  .c-desc {
    @include fontProperties(13px, 400, #fff);
    margin-top: 10px;
    @include animate(0.2s);
  }
  .c-application {
    margin-top: 20px;
    @include animate(0.3s);

    &.contact {
      margin-top: 40px;
      @include animate(0.2s);
    }
    span {
      @include fontProperties(20px, 700, #fff);
      background: linear-gradient(90deg, #4249fb 0%, #af57ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .form {
    @include animate(0.4s);
    margin-top: 15px;
    @include verticalFlex();
    gap: 10px;

    &.contact {
      @include animate(0.3s);
    }

    .form-submit {
      @include animate(0.5s);
      &.contact {
        @include animate(0.4s);
      }
      margin-top: 20px;
      @include setFlexEnd();
    }
  }

  .c-address {
    color: #fff;
    margin-top: 10px;
    .ca-texts {
      @include fontProperties(14px, normal, #fff);
      line-height: 25px;
      @include animate(0.5s);

      a {
        color: #fff;

        &:hover {
          background: linear-gradient(90deg, #3a47fa 0%, #b757ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .ca-title {
        font-weight: 600;
      }

      span {
        font-weight: 600;
      }
    }

    .ca-icons {
      @include animate(0.6s);
      @include flexAlign();
      gap: 30px;
      margin-top: 20px;
    }
  }

  @include respondTo("laptop") {
    .c-header {
      @include fontProperties(40px, 600, #fff);
      @include animate(0.1s);
    }
    .c-desc {
      @include fontProperties(16px, 500, #fff);
      margin-top: 40px;
      @include animate(0.2s);
    }
    .c-application {
      margin-top: 20px;
      @include animate(0.3s);

      &.contact {
        margin-top: 40px;
        @include animate(0.2s);
      }
      span {
        @include fontProperties(28px, 700, #fff);
        background: linear-gradient(90deg, #4249fb 0%, #af57ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .form {
      @include animate(0.4s);
      margin-top: 15px;
      @include verticalFlex();
      gap: 10px;

      &.contact {
        @include animate(0.3s);
      }

      .form-submit {
        @include animate(0.5s);
        &.contact {
          @include animate(0.4s);
        }
        margin-top: 20px;
        @include setFlexEnd();
      }
    }

    .c-address {
      color: #fff;
      margin-top: 10px;
      .ca-texts {
        @include fontProperties(14px, normal, #fff);
        line-height: 25px;
        @include animate(0.5s);

        a {
          color: #fff;

          &:hover {
            background: linear-gradient(90deg, #3a47fa 0%, #b757ff 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .ca-title {
          font-weight: 600;
        }

        span {
          font-weight: 600;
        }
      }

      .ca-icons {
        @include animate(0.6s);
        @include flexAlign();
        gap: 30px;
        margin-top: 20px;
      }
    }
  }
}

.social-icon-box {
  @include box(30px);
  @include setToCenter();
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, #3a47fa 0%, #b757ff 100%);
  }

  &.sib1 {
    @include animate(0.7s);
  }
  &.sib2 {
    @include animate(0.73s);
  }
  &.sib3 {
    @include animate(0.76s);
  }
  &.sib4 {
    @include animate(0.79s);
  }
}
