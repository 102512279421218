@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalFadeOut {
  to {
    opacity: 0;
    transform: translateY(100px);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes skeletonAnimation {
  to {
    background-position-x: -200%;
  }
}

@keyframes stageCardAnimation {
  from {
    transform: skew(90deg);
  }
  to {
    transform: skew(0deg);
  }
}

@keyframes popupAnimation {
  from {
    transform: scale(0.9);
    opacity: 0.7;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pageTransition {
  // from {
  //   opacity: 0;
  //   transform: translateY(60%) scale(0.95);
  // }
  // to {
  //   opacity: 1;
  //   transform: translateY(0) scale(1);
  // }
}

@keyframes modalBackdrop {
  from {
    background-color: rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
