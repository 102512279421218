@import "../../styles/mixins.scss";

.button-comp {
  @include removeDefaultStyle();
  height: 40px;
  border-radius: 5px;
  background-color: #071d33;
  padding: 5px 37px;
  @include fontProperties(13px, 600, #fff);
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: #0c2845;
  }
}
