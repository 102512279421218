@import "../../styles/mixins.scss";

.navbar-comp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15;
  padding: 25px 20px 0 25px;
  @include setSpaceBetween();

  .nc-logo {
    @include flexAlign();
    gap: 8px;
    position: relative;
    z-index: 3;

    .nc-image {
      vertical-align: middle;
      height: 23px;
    }
    .nc-line {
      @include box(0.5px, 30px);
      background-color: #fff;
      transition: all 0.1s ease-in;
    }
    .nc-content {
      @include fontProperties(11px, 400, #fff);
      line-height: 40px;
      transition: all 0.2s ease-in;
    }
  }
  .nc-ham {
    @include box(30px);
    @include setToCenter();
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    @include preventSelection();
    position: relative;
    z-index: 1;

    .spot-spreader {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #000;
      z-index: 0;
      border-radius: 50%;
      width: 0;
      height: 0;
      transition: all 0.7s ease-in-out;
    }

    $barWidth: 23px;
    $hoverRotationAngle: -20deg;

    .nch-bar {
      flex-shrink: 0;
      @include box($barWidth, 2px);
      border-radius: 2px;
      background-color: #ddd;
      transition: all 150ms ease-in-out;
      position: relative;
      z-index: 3;
    }

    &.active {
      .bar1 {
        transform-origin: left;
        transform: rotate(45deg);
        width: $barWidth;
      }
      .bar2 {
        transform-origin: center;
        scale: 0;
      }

      .bar3 {
        transform-origin: left;
        transform: rotate(-45deg);
        width: $barWidth;
      }

      .spot-spreader {
        @keyframes showSpreader {
          0% {
            @include box(0);
          }
          90% {
            @include box(5000px);
          }
          100% {
            @include box(5000px);
            display: none;
          }
        }
        animation: showSpreader 0.7s ease-in forwards;
      }
    }
  }

  @include respondTo("laptop") {
    padding: 40px 80px 0 120px;

    .nc-logo {
      @include flexAlign();
      gap: 11px;
      position: relative;
      z-index: 3;
      cursor: pointer;

      .nc-image {
        vertical-align: middle;
        height: 30px;
      }
      .nc-line {
        @include box(0.5px, 0px);
        background-color: #fff;
      }
      .nc-content {
        @include fontProperties(16px, 400, #fff);
        line-height: 70px;
        height: 70px;
        width: 0px;
        position: relative;
        max-width: 0;
        overflow: hidden;

        span {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          @include setTextInOneLine();
        }
      }

      &:hover {
        .nc-content {
          width: 127px;
          max-width: 200px;
        }
        .nc-line {
          @include box(0.5px, 40px);
        }
      }
    }
    .nc-ham {
      @include box(30px);
      @include setToCenter();
      flex-direction: column;
      gap: 6px;
      cursor: pointer;
      @include preventSelection();
      position: relative;
      z-index: 1;

      .spot-spreader {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #000;
        z-index: 0;
        border-radius: 50%;
        width: 0;
        height: 0;
        transition: all 0.7s ease-in-out;
      }

      $barWidth: 26px;
      $hoverRotationAngle: -20deg;

      .nch-bar {
        flex-shrink: 0;
        @include box($barWidth, 3px);
        border-radius: 2px;
        background-color: #ddd;
        transition: all 150ms ease-in-out;
        position: relative;
        z-index: 3;
      }

      &:hover {
        .bar1 {
          transform-origin: center;
          transform: rotate($hoverRotationAngle) translateY(-3px);
          width: 30px;
        }
        .bar2 {
          transform-origin: center;
          transform: rotate($hoverRotationAngle);
          width: 20px;
        }

        .bar3 {
          transform-origin: center;
          transform: rotate($hoverRotationAngle) translateY(3px);
          width: 30px;
        }
      }

      &.active {
        .bar1 {
          transform-origin: left;
          transform: rotate(45deg);
          width: $barWidth;
        }
        .bar2 {
          transform-origin: center;
          scale: 0;
        }

        .bar3 {
          transform-origin: left;
          transform: rotate(-45deg);
          width: $barWidth;
        }

        .spot-spreader {
          @keyframes showSpreader {
            0% {
              @include box(0);
            }
            90% {
              @include box(5000px);
            }
            100% {
              @include box(5000px);
              display: none;
            }
          }
          animation: showSpreader 0.7s ease-in forwards;
        }
      }
    }
  }
}

.navbar-page {
  @keyframes showPage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  position: fixed;
  height: 100vh;
  inset: 0;
  z-index: 13;
  background-color: #000;
  opacity: 0;
  animation: showPage 150ms 0.4s ease-in forwards;

  .np-sections {
    padding: 0px 20px;
    height: calc(100vh - 90px);
    overflow-y: auto;
    position: relative;
    z-index: 14;
    @include verticalFlex();
    gap: 20px;
    margin-top: 90px;
    padding-bottom: 20px;

    .np-left {
      display: flex;
      padding-top: 0px;
      gap: 15px;

      .np-nav {
        color: #fff;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
        @include animate(0.4s);

        &.npn2 {
          @include animate(0.5s);
        }

        span {
          transition: all 0.2s ease-in;
          display: inline-block;
          position: relative;
        }

        &.active {
          span {
            background: linear-gradient(90deg, #3a47fa 0%, #b757ff 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  @include respondTo("laptop") {
    .np-sections {
      padding: 0px 80px;
      height: calc(100vh - 110px);
      @include flexAlign();
      flex-direction: row;
      position: relative;
      z-index: 14;
      margin-top: 110px;

      .np-left {
        flex: 0.4;
        padding-left: 100px;
        @include verticalFlex();
        gap: 10px;

        .np-nav {
          color: #fff;
          font-size: 90px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
          @include animate(0.4s);

          &.npn2 {
            @include animate(0.5s);
          }

          span {
            transition: all 0.2s ease-in;
            display: inline-block;
            position: relative;

            &:hover {
              scale: 1.1;
            }
          }

          &.active {
            span {
              background: linear-gradient(90deg, #3a47fa 0%, #b757ff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }

      .np-right {
        flex: 0.6;
      }
    }
  }
}
