* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Maven Pro", sans-serif;

  // remove click effect for mobile
  -webkit-tap-highlight-color: transparent; // Remove default mobile highlight
}

/* html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "brandon-grotesque", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cdcbca;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  letter-spacing: 1.03px;
  line-height: 1.2em;
}

h1 {
  background: -webkit-linear-gradient(
    0deg,
    #cdcbca,
    aliceblue,
    #cdcbca,
    #2a3a3f
  );
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important;
}

h2 {
  background: -webkit-linear-gradient(
    0deg,
    #cdcbca,
    aliceblue,
    #cdcbca,
    #2a3a3f
  );
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important;
  font-size: 40px;
}

p {
  font-size: 20px;
  line-height: 1.4em;
}

button {
  background: transparent;
  display: inline-block;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 1.4em;
  border: 1px solid #fff;
  color: #fff;
  padding: 14px 40px;
  border-radius: 3px;
}

.row {
  width: 100%;
  padding: 0 40px;
} */
