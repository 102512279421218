@import "../../styles/mixins.scss";

.input-comp {
  .ic-input {
    width: 100%;
    height: 35px;
    background-color: transparent;
    @include removeDefaultStyle();
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 11px 13px;
    @include fontProperties(14px, 400, #fff);

    &::placeholder {
      color: #aaa;
    }
  }

  &.textarea {
    .ic-input {
      height: 100px;
    }
  }

  @include respondTo("laptop") {
    .ic-input {
      height: 40px;
    }

    &.textarea {
      .ic-input {
        height: 120px;
      }
    }
  }
}
