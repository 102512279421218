@import "../../styles/mixins.scss";

.comming-soon {
  position: fixed;
  inset: 0;
  @include box(100%, 100vh);
  background-color: #000;

  .cs-bg {
    position: absolute;
    inset: 0;
    @include box(100%);
    object-fit: cover;
  }

  .cs-body {
    position: absolute;
    inset: 0;
    z-index: 10;

    .csb-bg {
      position: absolute;
      inset: 0;
      @include box(100%);
      z-index: -1;
    }

    .csb-box {
      @include box(100%, 100vh);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;

      .csbb-title {
        font-size: 33px;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(270deg, #c758ff 14.47%, #2142f8 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 300px;
      }

      .csbb-desc {
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 5px;
      }

      .csb-time {
        @include flexAlign();
        justify-content: center;
        gap: 40px;
        margin-top: 40px;
        @include preventSelection();

        .csbt-sec {
          @include setToCenter();
          flex-direction: column;

          .csbts-label {
            color: #959595;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .csbts-value {
            color: #fff;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 4px;
          }
        }
      }
    }

    @include respondTo("laptop") {
      .csb-box {
        @include box(100%, 100vh);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 180px;

        .csbb-title {
          text-align: right;
          font-size: 65px;
          font-weight: 700;
          line-height: normal;
          background: linear-gradient(270deg, #c758ff 14.47%, #2142f8 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-top: 0px;
        }

        .csbb-desc {
          text-align: right;
          color: #fff;
          font-size: 40px;
          font-weight: 400;
          line-height: 70px;
          margin-top: 15px;
        }

        .csb-time {
          @include flexAlign();
          gap: 60px;
          margin-top: 40px;
          @include preventSelection();
          .csbt-sec {
            //   @include box(99px, 112px);
            //   border-radius: 10px;
            //   background: rgba(217, 217, 217, 0.1);
            //   backdrop-filter: blur(10px);

            @include setToCenter();
            flex-direction: column;

            .csbts-label {
              color: #959595;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .csbts-value {
              color: #fff;
              font-size: 35px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}
